<template>
  <div>
    <div v-if="errors" class="errors">{{ errors }}</div>
    <div v-if="info">
      <div class="widget-header">
        <span class="type">SPECIAL SCORES by Round</span>
        <span class="club-grade-year-season">
          <span class="club">{{ pagedata.club }}: </span>
          <span class="grade">{{ pagedata.grade }} </span>
          <span class="year">{{ pagedata.year }} </span>
          <span class="seasontitle">Season: </span
          ><span class="seasonvalue">{{ pagedata.season }} </span>
        </span>
      </div>
      <div>
        <table class="specials-by-round">
          <thead>
            <tr>
              <td class="roundheader">R</td>
              <td
                class="dataheader"
                v-for="(type, i) in pagedata.specialtypes"
                :key="i"
              >
                {{ type.name }}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, key, i) in groupedTypeByRound" :key="i">
              <td class="roundfield">{{ key }}</td>
              <td class="datafield" v-for="(type, n) in row" :key="n">
                {{ type.score }}
              </td>
            </tr>
            <tr class="totals">
              <td class="totalsfield">TOTALS:</td>
              <td class="totalsdatafield" v-for="(total, i) in totals" :key="i">
                {{ total }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="widget-footer">
          <p v-if="fulltable">
            <span v-for="(type, i) in pagedata.specialtypes" :key="i">
              {{ type.fullName }}, &nbsp;
            </span>
          </p>
          <p v-if="!fulltable">
            x
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//https://gist.github.com/JamieMason/0566f8412af9fe6a1d470aa1e089a752
const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

export default {
  name: "PlayerSpecialByRound",

  props: {
    club: Number,
    year: Number,
    grade: Number,
    season: Number,
    gametypeid: Number,
    playerid: Number,
    fulltable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      pagedata: null,
      info: null,
      errors: null,
      groupedByRound: null,
      groupedTypeByRound: null,
      totals: null
    };
  },

  created: function() {
    this.getdata();
  },

  methods: {
    getdata() {
      var url = "https://enterthescore.com/api/results/";
      //var url = "http://darts.local:8000/api/results/";
      if (this.club == null) {
        this.errors = "Club Information is missing";
        return;
      }
      if (this.year == null) {
        this.errors = "Year Information is missing";
        return;
      }
      if (this.grade == null) {
        this.errors = "Grade Information is missing";
        return;
      }
      if (this.season == null) {
        this.errors = "Season Information is missing";
        return;
      }
      if (this.playerid == null) {
        this.errors = "Player Id Information is missing";
        return;
      }

      url +=
        this.club +
        "/" +
        this.year +
        "/" +
        this.grade +
        "/" +
        this.season +
        "/playerspecialsbyround/" +
        this.playerid;

      this.axios
        .get(url)
        .then(response => {
          this.pagedata = response.data.pagedata;
          this.info = response.data.data;
          this.createRoundData();
        })
        .catch(error => {
          this.errors = error.response.data;
        });
    },

    createRoundData() {
      const groupByBrand = groupBy("round");
      this.groupedByRound = groupByBrand(this.info);

      var dataforeachround = {};
      var datafortotals = {};

      for (var stype in this.pagedata.specialtypes) {
        if (this.pagedata.specialtypes.hasOwnProperty(stype)) {
          dataforeachround[stype] = {
            id: this.pagedata.specialtypes[stype].id,
            name: this.pagedata.specialtypes[stype].name,
            score: "",
            type: this.pagedata.specialtypes[stype].type
          };
          datafortotals[stype] = 0;
        }
      }

      this.groupedTypeByRound = {};
      for (var round in this.groupedByRound) {
        if (this.groupedByRound.hasOwnProperty(round)) {
          this.groupedTypeByRound[round] = JSON.parse(
            JSON.stringify(dataforeachround)
          );
          for (var i = this.groupedByRound[round].length - 1; i >= 0; i--) {
            var type = this.groupedByRound[round][i].special_scores_type_id;
            if (dataforeachround[type].type == "array") {
              this.groupedTypeByRound[round][type].score +=
                this.groupedByRound[round][i].special_score_value + ", ";
            } else {
              this.groupedTypeByRound[round][type].score = this.groupedByRound[
                round
              ][i].special_score_value;
            }
          }
        }
      }

      var temp = {};
      for (var d in this.info) {
        if (this.info.hasOwnProperty(d)) {
          if (typeof temp[this.info[d].special_scores_type_id] == "undefined") {
            temp[this.info[d].special_scores_type_id] = [];
          }
          temp[this.info[d].special_scores_type_id].push(
            this.info[d].special_score_value
          );
        }
      }

      this.totals = datafortotals;
      for (var x in temp) {
        if (temp.hasOwnProperty(x)) {
          if (dataforeachround[x].type == "array") {
            this.totals[x] = temp[x].length;
          } else {
            this.totals[x] = temp[x].reduce((a, b) => a + b);
          }
        }
      }
    }
  }
};
</script>
<style scoped></style>
