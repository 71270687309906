<template>
  <div>
    <div v-if="errors" class="errors">{{ errors }}</div>
    <div v-if="info">
      <div class="widget-header">
        <span class="type"
          >{{ info.pagedata.type | uppercase }} Game Totals</span
        >
        <span class="club-grade-year-season">
          <span class="club">{{ info.pagedata.club }}: </span>
          <span class="grade">{{ info.pagedata.grade }} </span>
          <span class="year">{{ info.pagedata.year }} </span>
          <span class="seasontitle">Season: </span
          ><span class="seasonvalue">{{ info.pagedata.season }} </span>
        </span>
      </div>

      <div>
        <div>
          <span class="widget-ordered-text"> Ordered By TOTAL WINS only </span>
          <a
            @click.stop="showfulltable = !showfulltable"
            class="widget-show-more"
          >
            <span v-if="!showfulltable">Show</span
            ><span v-if="showfulltable">Hide</span> Full Table</a
          >
          <a
            v-if="showfulltable"
            @click.stop="legscount = !legscount"
            class="widget-show-more"
          >
            <span v-if="!legscount">Show</span
            ><span v-if="legscount">Hide</span> Legs Count</a
          >
        </div>

        <table class="game-totals">
          <thead>
            <tr>
              <td class="nameheader">Name</td>
              <td class="dataheader">G</td>
              <td class="highlightdata dataheader">W</td>
              <td class="dataheader" v-if="showfulltable">L</td>
              <td class="percentageheader">W%</td>
              <td v-if="showfulltable && legscount" class="legsheader">LP</td>
              <td v-if="showfulltable && legscount" class="legsheader">LW</td>
              <td v-if="showfulltable && legscount" class="legsheader">LL</td>
              <td
                v-if="showfulltable && legscount"
                class="legspercentageheader"
              >
                LW%
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(p, i) in info.data" :key="i">
              <td class="namefield">
                {{ p.player.name_first }} {{ p.player.name_last }}
              </td>
              <td class="datafield">{{ p.p_count }}</td>
              <td class="highlightdata datafield">{{ p.w_count }}</td>
              <td class="datafield" v-if="showfulltable">{{ p.l_count }}</td>
              <td class="percentagefield">
                {{ ((p.w_count / p.p_count) * 100) | roundNumberToString(1) }}%
              </td>
              <td class="datafield" v-if="showfulltable && legscount">
                {{ p.l_p_count }}
              </td>
              <td class="datafield" v-if="showfulltable && legscount">
                {{ p.l_w_count }}
              </td>
              <td class="datafield" v-if="showfulltable && legscount">
                {{ p.l_l_count }}
              </td>
              <td class="legspercentagefield" v-if="showfulltable && legscount">
                {{
                  ((p.l_w_count / p.l_p_count) * 100) | roundNumberToString(1)
                }}%
              </td>
            </tr>
          </tbody>
        </table>
        <div class="widget-footer">
          <p v-if="showfulltable">
            G = Games Played, W = Wins, L = Losses, W% = Wins Percentage
            <span v-if="legscount"
              >, LP = Legs Played, LW = Legs Won, LL = Legs Lost, LW% = Legs
              Percentage</span
            >
          </p>
          <p v-if="!showfulltable">
            G = Games Played W = Wins, W% = Wins Percentage
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GamesTotal",
  props: {
    club: Number,
    year: Number,
    grade: Number,
    season: Number,
    gametype: Number,
    fulltable: {
      type: Boolean,
      default: false
    },
    showlegscount: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      info: null,
      errors: null,
      legscount: this.showlegscount,
      showfulltable: this.fulltable
    };
  },

  created: function() {
    this.getdata();
  },

  methods: {
    getdata() {
      var url = "https://enterthescore.com/api/results/";
      if (this.club == null) {
        this.errors = "Club Information is missing";
        return;
      }
      if (this.year == null) {
        this.errors = "Year Information is missing";
        return;
      }
      if (this.grade == null) {
        this.errors = "Grade Information is missing";
        return;
      }
      if (this.season == null) {
        this.errors = "Season Information is missing";
        return;
      }
      if (this.gametype == null) {
        this.errors = "Game Type Information is missing";
        return;
      }

      url +=
        this.club +
        "/" +
        this.year +
        "/" +
        this.grade +
        "/" +
        this.season +
        "/game/" +
        this.gametype;

      this.axios
        .get(url)
        .then(response => {
          this.info = response.data;
        })
        .catch(error => {
          this.errors = error.response.data;
        });
    }
  }
};
</script>
<style scoped></style>
