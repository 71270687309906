<template>
  <div>
    <div v-if="errors" class="errors">{{ errors }}</div>
    <div v-if="info">
      <div class="widget-header">
        <span class="type">SINGLES v All Opponents</span>
        <span class="club-grade-year-season">
          <span class="club">{{ pagedata.club }}: </span>
          <span class="grade">{{ pagedata.grade }} </span>
          <span class="year">{{ pagedata.year }} </span>
          <span class="seasontitle">Season: </span
          ><span class="seasonvalue">{{ pagedata.season }} </span>
        </span>
      </div>
      <div>
        <table class="player-vs-opponents">
          <thead>
            <tr>
              <td class="nameheader">Name</td>
              <td class="highlightdata dataheader">Won 2 - 0</td>
              <td class="highlightdata dataheader">Won 2 - 1</td>
              <td class="highlightdata dataheader">Lost 0 - 2</td>
              <td class="highlightdata dataheader">Lost 1 - 2</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(p, i) in info" :key="i">
              <td class="namefield">{{ p[0].name }}</td>
              <td class="datafield" style="color:green">
                {{ getTotal(p, true, 1) }}
              </td>
              <td class="datafield" style="color:green">
                {{ getTotal(p, true, 2) }}
              </td>
              <td class="datafield" style="color:red">
                {{ getTotal(p, false, 1) }}
              </td>
              <td class="datafield" style="color:red">
                {{ getTotal(p, false, 2) }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="widget-footer">
          <p></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpecialScoresTable",
  props: {
    club: Number,
    year: Number,
    grade: Number,
    season: Number,
    specialtype: Number,
    playerid: Number
  },
  data() {
    return {
      pagedata: null,
      info: null,
      errors: null
    };
  },

  created: function() {
    this.getdata();
  },

  methods: {
    getdata() {
      var url = "https://enterthescore.com/api/results/";
      //var url = "http://darts.local:8000/api/results/";
      if (this.club == null) {
        this.errors = "Club Information is missing";
        return;
      }
      if (this.year == null) {
        this.errors = "Year Information is missing";
        return;
      }
      if (this.grade == null) {
        this.errors = "Grade Information is missing";
        return;
      }
      if (this.season == null) {
        this.errors = "Season Information is missing";
        return;
      }
      if (this.playerid == null) {
        this.errors = "Player Id Information is missing";
        return;
      }

      url +=
        this.club +
        "/" +
        this.year +
        "/" +
        this.grade +
        "/" +
        this.season +
        "/playergamestats/" +
        this.playerid;

      this.axios
        .get(url)
        .then(response => {
          //this.info = response.data;
          this.pagedata = response.data.pagedata;
          this.info = response.data.data;
        })
        .catch(error => {
          this.errors = error.response.data;
        });
    },

    getTotal(player, won, typeid) {
      console.log(player);
      var a = null;
      if (won) {
        a = player.find(item => item.win_type_id === typeid);
      } else {
        a = player.find(item => item.loss_type_id === typeid);
      }
      console.log(a);
      if (typeof a !== "undefined") {
        return a.total;
      }
      return "";
    }
  }
};
</script>
<style scoped></style>
