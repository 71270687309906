import { render, staticRenderFns } from "./WeeklyMatches.vue?vue&type=template&id=583513f5&scoped=true&"
import script from "./WeeklyMatches.vue?vue&type=script&lang=js&"
export * from "./WeeklyMatches.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "583513f5",
  null
  
)

export default component.exports