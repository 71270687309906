<template>
  <div class="fullwidth">
    <h3 class="playersearchtitle">Search Player Database</h3>
    <!--     <div style="text-align: center;">
      <a
        @click="openSearchSection"
        style="
        color: white;
        background-color: rgb(249, 97, 0);
        box-shadow: rgb(177, 175, 175) 1px 1px 2px;
        padding: 10px 20px;
        cursor: pointer;"
      >
        Search Player
      </a>
    </div> -->

    <div v-if="openSearch" class="fullwidth playersearchteams">
      <input
        type="text"
        class="form-control playersearchinput"
        v-model.lazy="keywords"
        v-debounce="500"
        placeholder="Type and wait! (min 2 letters)"
      />

      <!-- Data returned from Player Search -->
      <div v-if="results">
        <div v-if="results.length > 0" class="chooseaplayertitle">
          Please CHOOSE a Player:
        </div>
        <ul v-if="results.length > 0">
          <li
            v-for="result in results"
            :key="result.id"
            @click="selectedPlayer(result.id)"
          >
            <div class="playersbox">
              <div
                class="playername"
                v-html="
                  highlight(result.name_first.concat(space, result.name_last))
                "
              ></div>
              <div
                class="teamname"
                v-if="result.team"
                v-html="result.team.name"
              ></div>
            </div>
          </li>
        </ul>
      </div>

      <div v-if="showNewPlayer" class="playersearchnonefound">
        Player Not Found. Try Again.
      </div>

      <!-- If Player has been selected to Move -->
      <div v-if="comps" class="playersearchcomps">
        <div class="playername">
          {{ player.name_first.concat(space, player.name_last) | uppercase }}
        </div>
        <div v-if="player.team">
          <div class="playedfortitle">Teams Played For:</div>
          <ul>
            <li v-for="(team, i) in player.teams" :key="i">
              <div class="teamsbox">
                <div class="teamname">
                  {{ team.name | uppercase }}
                  <span v-if="i === 0" class="currentteam">Current Team: </span>
                </div>
                <div class="joineddiv">
                  Joined: {{ team.pivot.updated_at | formatdate }}
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="chooseacomptitle">
          Please CHOOSE a Comp to display info:
        </div>
        <ul v-if="comps.length > 0">
          <li
            v-for="comp in comps"
            :key="comp.id"
            @click="selectedComp(comp.id)"
            class="choosecomp"
          >
            <div class="compsbox">
              <div
                class="gradeseason"
                v-html="comp.gradeName + '  (s:' + comp.season + ')'"
              ></div>
              <div class="year" v-html="comp.year"></div>
            </div>
          </li>
        </ul>
      </div>

      <div v-if="!comps && !results" class="nocompsfound">
        No Comps Found for this Player.
      </div>

      <div v-if="compSelected">
        <app-player-game-stats
          :club="club"
          :year="compSelected.year"
          :grade="compSelected.grade_id"
          :season="compSelected.season"
          :playerid="player.id"
        ></app-player-game-stats>

        <!-- <app-player-vs-opponents-table
          :club="club"
          :year="compSelected.year"
          :grade="compSelected.grade_id"
          :season="compSelected.season"
          :playerid="player.id"
        ></app-player-vs-opponents-table>
        <app-opponents-vs-player-table
          :club="club"
          :year="compSelected.year"
          :grade="compSelected.grade_id"
          :season="compSelected.season"
          :playerid="player.id"
        ></app-opponents-vs-player-table> -->

        <app-player-matches-by-round-table
          :club="club"
          :year="compSelected.year"
          :grade="compSelected.grade_id"
          :season="compSelected.season"
          :playerid="player.id"
          gametypeid="1"
        ></app-player-matches-by-round-table>
        <app-player-matches-by-round-table
          :club="club"
          :year="compSelected.year"
          :grade="compSelected.grade_id"
          :season="compSelected.season"
          :playerid="player.id"
          gametypeid="2"
        ></app-player-matches-by-round-table>
        <app-player-specials-by-round-table
          :club="club"
          :year="compSelected.year"
          :grade="compSelected.grade_id"
          :season="compSelected.season"
          :playerid="player.id"
        ></app-player-specials-by-round-table>
      </div>

      <div v-if="errors" class="errors">{{ errors }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    club: Number
  },

  data() {
    return {
      selectedTeam: null,
      temp: null,
      keywords: null,
      results: [],
      space: " ",
      errors: "",
      player: null,
      showNewPlayer: false,
      success: false,
      openSearch: true,
      playerid: null,
      comps: null,
      compid: null,
      compSelected: null
    };
  },

  watch: {
    keywords() {
      if (this.keywords !== null) {
        this.keywords = this.keywords.trim();
        if (this.keywords != "") {
          if (this.keywords.length > 1) {
            this.fetch();
          } else {
            this.results = [];
          }
        }
      }
    }
  },

  computed: {},

  methods: {
    fetch() {
      this.clearFields();
      this.success = false;

      this.axios
        .post("https://enterthescore.com/api/player/search", {
          keywords: this.keywords,
          club_id: this.club
        })
        .then(response => {
          this.results = response.data;
          if (response.data.length == 0) {
            this.showNewPlayer = true;
          }
        })
        .catch(error => {
          if (error.response.status === 500) {
            this.errors = "Sorry Server Error. Please contact Admin.";
          }
        });
    },
    highlight(text) {
      return text.replace(
        new RegExp(this.keywords, "gi"),
        '<span class="highlightMatch">' + this.keywords + "</span>"
      );
    },
    selectedPlayer(id) {
      this.errors = null;
      this.showNewPlayer = false;
      this.temp = this.keywords;
      this.keywords = "";
      this.results = [];
      this.axios
        .post("https://enterthescore.com/api/player/getdetails", {
          player_id: id,
          club_id: this.club
        })
        .then(response => {
          this.player = response.data.player;
          this.comps = response.data.comps;
        })
        .catch(error => (this.errors = error.message));
    },

    selectedComp(id) {
      this.compSelected = this.comps.find(x => x.id === id);
    },

    clearFields() {
      this.showNewPlayer = false;
      this.player = null;
      this.temp = null;
      this.results = [];
      this.errors = "";
      this.playerid = null;
      this.comps = null;
      this.compid = null;
      this.compSelected = null;
      this.player = null;
    },

    openSearchSection() {
      this.openSearch = !this.openSearch;
      this.clearFields();
      this.keywords = null;
    }
  }
};
</script>
<style></style>
