<template>
  <div>
    <div v-if="errors" class="errors">{{ errors }}</div>
    <div v-if="info">
      <div class="widget-header">
        <span class="type">{{ info.pagedata.specialtype.fullName }}</span>
        <span class="club-grade-year-season">
          <span class="club">{{ info.pagedata.club }}: </span>
          <span class="grade">{{ info.pagedata.grade }} </span>
          <span class="year">{{ info.pagedata.year }} </span>
          <span class="seasontitle">Season: </span
          ><span class="seasonvalue">{{ info.pagedata.season }} </span>
        </span>
      </div>
      <div>
        <a @click.stop="changeList" class="widget-show-more">
          Show <span v-if="!showFullList">Full</span
          ><span v-if="showFullList">Short</span> List</a
        >
        <table class="speacial-scores-table">
          <thead>
            <tr>
              <td class="nameheader">Name</td>
              <td
                class="dataheader"
                v-if="
                  info.pagedata.specialtype.type === 'array' ||
                    info.pagedata.specialtype.type === 'int'
                "
              >
                #
              </td>
              <td
                class="highlightdata dataheader"
                v-if="info.pagedata.specialtype.type === 'array'"
              >
                {{ info.pagedata.specialtype.name }}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(player, index) in displayList" :key="index">
              <td class="namefield">
                {{ player.player.name_first }} {{ player.player.name_last }}
              </td>
              <td
                class="datafield"
                v-if="info.pagedata.specialtype.type === 'array'"
              >
                {{ player.count }}
              </td>
              <td class="highlightdata datafield">
                {{ player.special_score_value }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="widget-footer"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpecialScoresTable",
  props: {
    club: Number,
    year: Number,
    grade: Number,
    season: Number,
    specialtype: Number
  },
  data() {
    return {
      info: null,
      errors: null,
      playerScoreCounts: null,
      displayList: null,
      shortList: null,
      fullList: null,
      showFullList: false
    };
  },

  created: function() {
    this.getdata();
  },

  methods: {
    getdata() {
      var url = "https://enterthescore.com/api/results/";
      //var url = "http://darts.local:8000/api/results/";
      if (this.club == null) {
        this.errors = "Club Information is missing";
        return;
      }
      if (this.year == null) {
        this.errors = "Year Information is missing";
        return;
      }
      if (this.grade == null) {
        this.errors = "Grade Information is missing";
        return;
      }
      if (this.season == null) {
        this.errors = "Season Information is missing";
        return;
      }
      if (this.specialtype == null) {
        this.errors = "Special Score Type Information is missing";
        return;
      }

      url +=
        this.club +
        "/" +
        this.year +
        "/" +
        this.grade +
        "/" +
        this.season +
        "/specials/" +
        this.specialtype;

      this.axios
        .get(url)
        .then(response => {
          this.info = response.data;
          this.groupMultiple();
        })
        .catch(error => {
          this.errors = error.response.data;
        });
    },

    groupMultiple() {
      //var x = { name: "", count: 0, score: 0 };
      this.playerScoreCounts = {};
      this.fullList = [];
      var combineWithOutCount = {};
      var data = this.info.data;
      for (var i = data.length - 1; i >= 0; i--) {
        var id = data[i].player_id;
        var val = data[i].special_score_value;

        if (!this.playerScoreCounts.hasOwnProperty(id)) {
          this.playerScoreCounts[id] = {};
        }
        if (!this.playerScoreCounts[id].hasOwnProperty(val)) {
          this.playerScoreCounts[id][val] = 0;
        }

        if (this.playerScoreCounts[id].hasOwnProperty(val)) {
          this.playerScoreCounts[id][val] += 1;
        } else {
          this.playerScoreCounts[id][val] = 1;
        }

        if (!combineWithOutCount.hasOwnProperty(val)) {
          combineWithOutCount[val] = {};
        }
        combineWithOutCount[val][id] = {
          player: data[i].player,
          player_id: data[i].player_id,
          special_score_value: parseInt(val),
          count: 0
        };
      }

      var combineWithCount = {};
      for (var score in combineWithOutCount) {
        if (combineWithOutCount.hasOwnProperty(score)) {
          for (var player in combineWithOutCount[score]) {
            if (combineWithOutCount[score].hasOwnProperty(player)) {
              if (!combineWithCount.hasOwnProperty(score)) {
                combineWithCount[score] = combineWithOutCount[score];
              }
              combineWithCount[score][player] =
                combineWithOutCount[score][player];
              combineWithCount[score][player].count = this.playerScoreCounts[
                player
              ][score];
            }
          }
        }
      }

      for (var s in combineWithCount) {
        if (combineWithCount.hasOwnProperty(s)) {
          for (var p in combineWithCount[s]) {
            if (combineWithCount[s].hasOwnProperty(p)) {
              this.fullList.push(combineWithCount[s][p]);
            }
          }
        }
      }

      this.fullList.sort((a, b) => {
        if (a.special_score_value === b.special_score_value) {
          // Count is only important when Special Val are the same
          if (a.count) {
            if (a.count === b.count) {
              // Names is only important when Count are the same
              var p1 = a.player.name_first + "" + a.player.name_last;
              var p2 = b.player.name_first + "" + b.player.name_last;
              return p1.toLowerCase() > p2.toLowerCase() ? 1 : -1;
            }
            return b.count - a.count;
          } else {
            // No Count for some Scores
            var p1a = a.player.name_first + "" + a.player.name_last;
            var p2b = b.player.name_first + "" + b.player.name_last;
            return p1a.toLowerCase() > p2b.toLowerCase() ? 1 : -1;
          }
        }
        if (this.info.pagedata.specialtype.orderBy) {
          if (this.info.pagedata.specialtype.orderBy === "asc") {
            return a.special_score_value > b.special_score_value ? 1 : -1;
          }
        }
        return a.special_score_value < b.special_score_value ? 1 : -1;
      });

      this.shortList = this.fullList.slice(0, 10);
      this.displayList = this.shortList;
    },

    changeList() {
      this.showFullList = !this.showFullList;
      this.displayList = this.showFullList ? this.fullList : this.shortList;
    }
  }
};
</script>
<style scoped></style>
