<template>
  <div>
    <div v-if="errors" class="errors">{{ errors }}</div>
    <div v-if="info">
      <div class="widget-header">
        <span class="type">
          {{ pagedata.gametype | uppercase }} TOTALS by Round
        </span>
        <span class="club-grade-year-season">
          <span class="club">{{ pagedata.club }}: </span>
          <span class="grade">{{ pagedata.grade }} </span>
          <span class="year">{{ pagedata.year }} </span>
          <span class="seasontitle">Season: </span
          ><span class="seasonvalue">{{ pagedata.season }} </span>
        </span>
      </div>
      <div>
        <a
          @click.stop="showfulltable = !showfulltable"
          class="widget-show-more"
        >
          <span v-if="!showfulltable">Show</span
          ><span v-if="showfulltable">Hide</span> Full Table</a
        >
        <a
          v-if="showfulltable"
          @click.stop="legscount = !legscount"
          class="widget-show-more"
        >
          <span v-if="!legscount">Show</span
          ><span v-if="legscount">Hide</span> Legs Count</a
        >
        <table class="player-matches-by-round">
          <thead>
            <tr>
              <td class="roundheader">R</td>
              <td class="teamheader">Team</td>
              <td class="dataheader">G</td>
              <td class="highlightdata dataheader">W</td>
              <td v-if="showfulltable" class="dataheader">L</td>
              <td class="percentageheader">W%</td>
              <td v-if="showfulltable && legscount" class="legsheader">
                LP
              </td>
              <td v-if="showfulltable && legscount" class="legsheader">
                LW
              </td>
              <td v-if="showfulltable && legscount" class="legsheader">
                LL
              </td>
              <td
                v-if="showfulltable && legscount"
                class="legspercentageheader"
              >
                LW%
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(p, i) in info" :key="i">
              <td class="roundfield">{{ p.round }}</td>
              <td class="teamfield">{{ getOppositionName(p) }}</td>
              <td class="datafield">{{ p.played_count }}</td>
              <td class="highlightdata">{{ p.won_count }}</td>
              <td v-if="showfulltable" class="datafield">{{ p.lost_count }}</td>
              <td class="percentagefield">
                {{
                  ((p.won_count / p.played_count) * 100)
                    | roundNumberToString(1)
                }}%
              </td>
              <td v-if="showfulltable && legscount" class="datafield">
                {{ p.legs_played_count }}
              </td>
              <td v-if="showfulltable && legscount" class="datafield">
                {{ p.legs_won_count }}
              </td>
              <td v-if="showfulltable && legscount" class="datafield">
                {{ p.legs_lost_count }}
              </td>
              <td v-if="showfulltable && legscount" class="percentagefield">
                {{
                  ((p.legs_won_count / p.legs_played_count) * 100)
                    | roundNumberToString(1)
                }}%
              </td>
            </tr>
            <tr class="totals">
              <td></td>
              <td class="totalsfield">TOTALS:</td>
              <td class="totalsdatafield">{{ totals.played_count }}</td>
              <td class="totalsdatafield">{{ totals.won_count }}</td>
              <td v-if="showfulltable" class="totalsdatafield">
                {{ totals.lost_count }}
              </td>
              <td class="totalspercentagefield">
                {{
                  ((totals.won_count / totals.played_count) * 100)
                    | roundNumberToString(1)
                }}%
              </td>
              <td v-if="showfulltable && legscount" class="totalsdatafield">
                {{ totals.legs_played_count }}
              </td>
              <td v-if="showfulltable && legscount" class="totalsdatafield">
                {{ totals.legs_won_count }}
              </td>
              <td v-if="showfulltable && legscount" class="totalsdatafield">
                {{ totals.legs_lost_count }}
              </td>
              <td
                v-if="showfulltable && legscount"
                class="totalspercentagefield"
              >
                {{
                  ((totals.legs_won_count / totals.legs_played_count) * 100)
                    | roundNumberToString(1)
                }}%
              </td>
            </tr>
          </tbody>
        </table>
        <div class="widget-footer">
          <p v-if="showfulltable">
            R = Round, G = Games Played, W = Wins, L = Losses, W% = Wins
            Percentage
            <span v-if="legscount">
              , LP = Legs Played, LW = Legs Won, LL = Legs Lost, LW% = Legs
              Percentage
            </span>
          </p>
          <p v-if="!showfulltable">
            G = Games Played W = Wins, W% = Wins Percentage
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlayerMatchesByRound",

  props: {
    club: Number,
    year: Number,
    grade: Number,
    season: Number,
    gametypeid: Number,
    playerid: Number,
    fulltable: {
      type: Boolean,
      default: true
    },
    showlegscount: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pagedata: null,
      info: null,
      errors: null,
      totals: null,
      legscount: this.showlegscount,
      showfulltable: this.fulltable
    };
  },

  created: function() {
    this.getdata();
  },

  methods: {
    getdata() {
      var url = "https://enterthescore.com/api/results/";
      //var url = "http://darts.local:8000/api/results/";
      if (this.club == null) {
        this.errors = "Club Information is missing";
        return;
      }
      if (this.year == null) {
        this.errors = "Year Information is missing";
        return;
      }
      if (this.grade == null) {
        this.errors = "Grade Information is missing";
        return;
      }
      if (this.season == null) {
        this.errors = "Season Information is missing";
        return;
      }
      if (this.playerid == null) {
        this.errors = "Player Id Information is missing";
        return;
      }
      if (this.gametypeid == null) {
        this.errors = "Game Type Id Information is missing";
        return;
      }

      url +=
        this.club +
        "/" +
        this.year +
        "/" +
        this.grade +
        "/" +
        this.season +
        "/playermatchesbyround/" +
        this.playerid +
        "/" +
        this.gametypeid;

      this.axios
        .get(url)
        .then(response => {
          this.pagedata = response.data.pagedata;
          this.info = response.data.data;
          this.totals = this.info.reduce((a, b) => {
            Object.keys(b).forEach(key => {
              if (key !== "match" && key !== "match_id" && key !== "round") {
                a[key] = (a[key] || 0) + b[key];
              }
            });
            return a;
            // played: a.played_count + b.played_count,
            // won: a.won_count + b.won_count,
            // lost: a.lost_count + b.lost_count,
            // legs: a.legs_played_count + b.legs_played_count,
            // legs_won: a.legs_won_count + b.legs_won_count,
            // legs_lost: a.legs_lost_count + b.legs_lost_count
          }, {});
        })
        .catch(error => {
          this.errors = error.response.data;
        });
    },
    getOppositionName(data) {
      if (data.match.team1_id !== this.pagedata.player.team_id) {
        return data.match.team1.name;
      }
      if (data.match.team2_id !== this.pagedata.player.team_id) {
        return data.match.team2.name;
      }
      return "";
    }
  }
};
</script>
<style scoped></style>
