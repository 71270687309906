import Vue from "vue";
import GT from "./components/GameTotals.vue";
import ST from "./components/StandingsTable.vue";
import SS from "./components/SpecialScoresTable.vue";
import PG from "./components/PlayerGameStats.vue";
import PO from "./components/PlayerVsOpponents.vue";
import OP from "./components/OpponentsVsPlayer.vue";
import MR from "./components/PlayerMatchesByRound.vue";
import SR from "./components/PlayerSpecialsByRound.vue";
import PS from "./components/PlayerSearch.vue";
import WM from "./components/WeeklyMatches.vue";

require("@/assets/app.css");

// import router from './router';
// import store from './store/index';
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

import vueCustomElement from "vue-custom-element";
import "document-register-element/build/document-register-element";

Vue.use(vueCustomElement);
// App.store = store;
// App.router = router;

Vue.filter("formatdate", function(value) {
  if (!value) return "";
  value = new Date(value).toLocaleDateString();
  return value;
});

Vue.filter("shortendate", function(value) {
  if (!value) return "";
  value = value.substring(0, 10);
  return value;
});

Vue.filter("uppercase", function(value) {
  if (!value) return "";
  value = value.toString();
  return value.toUpperCase();
});

Vue.filter("roundNumberToString", function(value, precision) {
  if (!value) return 0;
  var multiplier = Math.pow(10, precision || 0);
  var num = Math.round(value * multiplier) / multiplier;
  return num.toString();
});

function debounce(fn, delay = 500) {
  var timeoutID = null;

  return function() {
    clearTimeout(timeoutID);

    var args = arguments;
    var that = this;

    timeoutID = setTimeout(function() {
      fn.apply(that, args);
    }, delay);
  };
}
Vue.directive("debounce", (el, binding) => {
  if (binding.value !== binding.oldValue) {
    // window.debounce is our global function what we defined at the very top!
    el.oninput = debounce(() => {
      el.dispatchEvent(new Event("change"));
    }, parseInt(binding.value) || 500);
  }
});
Vue.customElement("app-game-totals", GT);
Vue.customElement("app-standings-table", ST);
Vue.customElement("app-special-scores-table", SS);
Vue.customElement("app-player-game-stats", PG);
Vue.customElement("app-player-vs-opponents-table", PO);
Vue.customElement("app-opponents-vs-player-table", OP);
Vue.customElement("app-player-matches-by-round-table", MR);
Vue.customElement("app-player-specials-by-round-table", SR);
Vue.customElement("app-player-search", PS);
Vue.customElement("app-weekly-matches", WM);
