<template>
  <div>
    <div v-if="errors" class="errors">{{ errors }}</div>
    <div v-if="info">
      <div v-for="(d, i) in info" :key="i">
        <div class="widget-header">
          <span class="type">Competition Results</span>
          <span class="club-grade-year-season">
            <span class="club">{{ d.club.name }}: </span>
            <span class="grade">{{ d.gradeName }} </span>
            <span class="year">{{ d.year }} </span>
            <span class="seasontitle">Season: </span
            ><span class="seasonvalue">{{ d.season }} </span>
          </span>
        </div>

        <div>
          <a
            @click.stop="showfulltable = !showfulltable"
            class="widget-show-more"
          >
            <span v-if="!showfulltable">Show</span
            ><span v-if="showfulltable">Hide</span> Full Table</a
          >
          <a
            v-if="showfulltable"
            @click.stop="gamescount = !gamescount"
            class="widget-show-more"
          >
            <span v-if="!gamescount">Show</span
            ><span v-if="gamescount">Hide</span> Games Count</a
          >
          <table class="standings-table">
            <thead>
              <tr>
                <td class="nameheader">Team</td>
                <td class="dataheader">M</td>
                <td class="dataheader">W</td>
                <td class="dataheader" v-if="showfulltable">L</td>
                <td class="dataheader" v-if="showfulltable">D</td>
                <td v-if="showfulltable && gamescount" class="legsheader">
                  GW
                </td>
                <td v-if="showfulltable && gamescount" class="legsheader">
                  GL
                </td>
                <td v-if="showfulltable && gamescount" class="legsheader">
                  GD
                </td>
                <td class="dataheader">P</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(t, i) in d.teams" :key="i">
                <td class="namefield">{{ t.name }}</td>
                <td class="datafield">{{ t.pivot.played }}</td>
                <td class="datafield">{{ t.pivot.won }}</td>
                <td class="datafield" v-if="showfulltable">
                  {{ t.pivot.lost }}
                </td>
                <td class="datafield" v-if="showfulltable">
                  {{ t.pivot.drawn }}
                </td>
                <td class="datafield" v-if="showfulltable && gamescount">
                  {{ t.pivot.for }}
                </td>
                <td class="datafield" v-if="showfulltable && gamescount">
                  {{ t.pivot.against }}
                </td>
                <td class="datafield" v-if="showfulltable && gamescount">
                  {{ t.pivot.for - t.pivot.against }}
                </td>
                <td class="datafield">{{ t.pivot.points }}</td>
              </tr>
            </tbody>
          </table>
          <div class="widget-footer">
            <p v-if="showfulltable">
              M = Matches Played, W = Wins, L = Losses, D = Draws, P = Points
              <span v-if="gamescount">
                , GW = Games Won, GL = Games Lost, GD = Difference Games
              </span>
            </p>
            <p v-if="!showfulltable">
              M = Matches Played, W = Wins, P = Points
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StandingsTable",
  props: {
    club: Number,
    year: Number,
    grade: Number,
    season: Number,
    fulltable: {
      type: Boolean,
      default: false
    },
    showgamescount: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      info: null,
      errors: null,
      gamescount: this.showgamescount,
      showfulltable: this.fulltable
    };
  },

  created: function() {
    this.getdata();
  },

  methods: {
    getdata() {
      var url = "https://enterthescore.com/api/results/";
      if (this.club == null) {
        this.errors = "Club Information is missing";
        return;
      }
      if (this.year == null) {
        this.errors = "Year Information is missing";
        return;
      }
      if (this.grade == null) {
        this.errors = "Grade Information is missing";
        return;
      }

      if (this.season == "" || typeof this.season === "undefined") {
        url += this.club + "/" + this.year + "/" + this.grade;
      } else {
        url +=
          this.club + "/" + this.year + "/" + this.grade + "/" + this.season;
      }

      this.axios
        .get(url)
        .then(response => {
          if (Array.isArray(response.data)) {
            this.info = response.data;
          } else {
            this.info = [];
            this.info.push(response.data);
          }
        })
        .catch(error => {
          this.errors = error.response.data;
        });
    }
  }
};
</script>
<style scoped></style>
