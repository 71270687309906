<template>
  <div>
    <div v-if="errors" class="errors">{{ errors }}</div>
    <div v-if="info == null || info.length == 0">
      <div class="widget-header" style="padding-top: 10px">
        <span class="type">Weekly Matches </span>
        <span class="seasontitle">No Matches Found for this week.</span>
      </div>
    </div>
    <div v-if="info">
      <div v-for="(singleComp, index) in info" :key="index">
        <template v-if="singleComp.length > 0">
          <div class="widget-header" style="padding-top: 10px">
            <span class="type"
              >{{ singleComp[0].comp.gradeName }} Grade Weekly Matches
            </span>
            <span class="club-grade-year-season">
              <span class="club">{{ singleComp[0].special_round_name }} </span>
              <span class="year">{{ singleComp[0].comp.year }} </span>
              <span class="seasontitle">Season: </span>
              <span class="seasonvalue">{{ singleComp[0].comp.season }} </span>
            </span>
          </div>
        </template>
        <div>
          <a
            @click.stop="showfulltable = !showfulltable"
            class="widget-show-more"
          >
            <span v-if="!showfulltable">Show</span
            ><span v-if="showfulltable">Hide</span> Results</a
          >
          <div>
            <table class="standings-table">
              <thead>
                <tr>
                  <td class="dataheader" style="text-align: center;">
                    Team 1
                  </td>
                  <td class="dataheader" style="text-align: center;">
                    Team 2
                  </td>
                  <td class="dataheader" style="text-align: center;">Venue</td>
                  <td class="dataheader" style="text-align: center;">Date</td>
                  <td
                    class="dataheader"
                    style="text-align: center;"
                    v-if="showfulltable"
                  >
                    Result
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(d, i) in singleComp" :key="i">
                  <template v-if="d.team1_id == null || d.team2_id == null">
                    <td v-if="d.team1_id == null" style="text-align: center;">
                      {{ d.team2.name }}
                    </td>
                    <td
                      v-else-if="d.team2_id == null"
                      style="text-align: center;"
                    >
                      {{ d.team1.name }}
                    </td>
                    <td style="text-align: center;">>BYE</td>
                  </template>
                  <template v-if="d.team1_id != null && d.team2_id != null">
                    <td class="datafield" style="text-align: center;">
                      {{ d.team1.name }}
                    </td>
                    <td class="datafield" style="text-align: center;">
                      {{ d.team2.name }}
                    </td>
                  </template>
                  <template v-if="d.venue_id != null">
                    <td class="datafield" style="text-align: center;">
                      {{ d.venue.name }}
                    </td>
                  </template>
                  <template v-else>
                    <td class="datafield" style="text-align: center;">></td>
                  </template>
                  <td class="datafield" style="text-align: center;">
                    {{ d.date.date | shortendate }}
                  </td>
                  <td
                    class="datafield"
                    v-if="showfulltable"
                    style="text-align: center;"
                  >
                    <template v-if="d.is_score_confirmed">
                      <template v-if="d.team1_score == 0 && d.team2_score == 0">
                        <template v-if="d.winner_id == d.team1_id">
                          W : F
                        </template>
                        <template v-else-if="d.winner_id == d.team2_id">
                          F : W
                        </template>
                      </template>
                      <template v-else>
                        {{ d.team1_score }} : {{ d.team2_score }}
                      </template>
                    </template>
                    <template
                      v-else-if="
                        d.is_score_confirmed == 0 &&
                          d.team1_score != null &&
                          d.team2_score != null
                      "
                    >
                      {{ d.team1_score }} : {{ d.team2_score }} TBC
                    </template>
                    <template v-else>
                      :
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="widget-footer"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Weekly Games",
  props: {
    club: Number,
    comp: Number,
    fulltable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      info: null,
      errors: null,
      showfulltable: this.fulltable
    };
  },

  created: function() {
    this.getdata();
  },

  methods: {
    getdata() {
      var url = "https://enterthescore.com/api/matches-this-week/";
      //var url = "http://darts.local/api/matches-this-week/";
      if (this.club == null) {
        this.errors = "Club Information is missing";
        return;
      }

      if (this.comp == "" || typeof this.comp === "undefined") {
        url += this.club;
      } else {
        url += this.club + "/" + this.comp;
      }

      this.axios
        .get(url)
        .then(response => {
          console.log(response.data);

          if (
            Array.isArray(response.data) ||
            response.data.constructor === Object
          ) {
            this.info = response.data;
          } else {
            this.info = [];
            this.info.push(response.data);
          }

          console.log(this.info);
        })
        .catch(error => {
          this.errors = error.response.data;
        });
    }
  }
};
</script>
<style scoped></style>
