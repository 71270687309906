<template>
  <div>
    <div v-if="errors" class="errors">{{ errors }}</div>
    <div v-if="info">
      <div class="widget-header">
        <span class="type">SINGLES WINS v All Opponents</span>
        <span class="club-grade-year-season">
          <span class="club">{{ pagedata.club }}: </span>
          <span class="grade">{{ pagedata.grade }} </span>
          <span class="year">{{ pagedata.year }} </span>
          <span class="seasontitle">Season: </span
          ><span class="seasonvalue">{{ pagedata.season }} </span>
        </span>
      </div>
      <div>
        <table class="player-vs-opponents">
          <thead>
            <tr>
              <td class="nameheader">Name</td>
              <td class="highlightdata dataheader">2 - 0</td>
              <td class="highlightdata dataheader">2 - 1</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(p, i) in info" :key="i">
              <td class="namefield">{{ getPlayerName(p) }}</td>
              <td class="datafield">{{ getTotal(p, 1) }}</td>
              <td class="datafield">{{ getTotal(p, 2) }}</td>
            </tr>
          </tbody>
        </table>
        <div class="widget-footer">
          <p></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//https://gist.github.com/JamieMason/0566f8412af9fe6a1d470aa1e089a752
const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

export default {
  name: "SpecialScoresTable",
  props: {
    club: Number,
    year: Number,
    grade: Number,
    season: Number,
    specialtype: Number,
    playerid: Number
  },
  data() {
    return {
      pagedata: null,
      info: null,
      errors: null
    };
  },

  created: function() {
    this.getdata();
  },

  methods: {
    getdata() {
      var url = "https://enterthescore.com/api/results/";
      //var url = "http://darts.local:8000/api/results/";
      if (this.club == null) {
        this.errors = "Club Information is missing";
        return;
      }
      if (this.year == null) {
        this.errors = "Year Information is missing";
        return;
      }
      if (this.grade == null) {
        this.errors = "Grade Information is missing";
        return;
      }
      if (this.season == null) {
        this.errors = "Season Information is missing";
        return;
      }
      if (this.playerid == null) {
        this.errors = "Player Id Information is missing";
        return;
      }

      url +=
        this.club +
        "/" +
        this.year +
        "/" +
        this.grade +
        "/" +
        this.season +
        "/playervsopponents/" +
        this.playerid;

      this.axios
        .get(url)
        .then(response => {
          //this.info = response.data;
          this.pagedata = response.data.pagedata;
          const groupByBrand = groupBy("opponent_id");
          this.info = groupByBrand(response.data.data);
        })
        .catch(error => {
          this.errors = error.response.data;
        });
    },
    getTotal(playerarray, wintype) {
      var a = playerarray.find(item => item.win_type_id === wintype);
      if (typeof a !== "undefined") {
        return a.total;
      }
      return 0;
    },
    getPlayerName(playerarray) {
      var a = playerarray.find(item => item.player_opposition !== "undefined");
      if (typeof a !== "undefined") {
        return (
          a.player_opposition.name_first + " " + a.player_opposition.name_last
        );
      }
      return "";
    }
  }
};
</script>
<style scoped></style>
